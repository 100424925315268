@import './_reset.scss';
@import './transition.scss';
@import './popup.global.scss';
@import '../components/UI/general_UI.scss';

:root {
    --tg_bg_theme_color: --tg-theme-bg-color;
    --tg_text_color: --tg-theme-text-color;
    --tg_hint_color: --tg-theme-hint-color;
    --tg_button_color: --tg-theme-button-color;

    --black_color: #000000;
    --dark_black: #0e1019;
    --light_black: #2e3346;

    --blue_color: #027bff;
    --light_blue: #4eb5ff;

    --white_color: #ffffff;
    --grey_color: #f3f4f6;
    --dark_grey_color: #616576;

    --lalau_color: rgb(255, 78, 193);

    --page_x_padding: 9px;
    --page_y_padding: 20px;

    --nav_height: 50px;

    font-size: 15px;

    background-color: var(--dark_black);
}

* {
    -webkit-tap-highlight-color: transparent;

    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-width: none;
    // overscroll-behavior: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    color: var(--white_color);

    input,
    input:before,
    input:after {
        -webkit-user-select: initial;
        -khtml-user-select: initial;
        -moz-user-select: initial;
        -ms-user-select: initial;
        user-select: initial;
    }
}

.hidden-scroll {
    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-width: none;
    overscroll-behavior: none;

    &::-webkit-scrollbar {
        background: transparent;
        display: none; // Safari and Chrome
        -webkit-appearance: none;
        width: 0;
        height: 0;
    }
}

.page {
    padding-bottom: calc(var(--nav_height) + 20px);

    &__header {
        padding: 15px 0 25px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-70%);

            svg {
                flex-shrink: 0;
            }
        }

        .side-bar-control {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-70%);
        }
    }

    &__title {
        padding-bottom: 14px;

        p {
            color: var(--white_color);
            font-weight: 800;
            font-size: 0.875rem;
            text-align: center;
            text-transform: uppercase;
        }
    }

    &__top-sticky-wrapper {
        position: sticky;
        top: calc(0px - var(--page_y_padding));
        background-color: var(--dark_black);
        box-shadow: 0 0 7px 7px var(--dark_black);
        z-index: 1;

        .page__title {
            margin-bottom: 7px;
        }
    }

    &__content {
    }
}
