.base-input__style {
	border-radius: 10px;
	background-color: var(--light_black);
	font-size: 0.875rem;
	border: none;
	outline: none;
	padding: 17px 13px;
	width: 100%;
	box-sizing: border-box;
	border: 1px solid transparent;
}

.base-error {
	border-color: red !important;
}
