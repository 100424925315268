.popup {
    &-wrapper {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 1000;
        display: none;

        &__active {
            background: rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(2px);
            transition: 0.5s all;
            display: initial;
        }
    }

    &-modal {
        position: absolute;
        width: 100%;
        background: #ffffff;
        box-sizing: border-box;
        max-height: 650px;

        &.popup-top-bt {
            top: 0;
            border-radius: 0 0 50px 0;

            .popup-modal__content {
                padding: var(--page_y_padding) var(--page_x_padding) 40px var(--page_x_padding);
            }
        }

        &.popup-bt-top {
            bottom: 0;
            border-radius: 50px 50px 0 0;
        }

        &__content {
            padding: var(--page_x_padding);
            padding-top: 27px;
            height: 100%;
            box-sizing: border-box;
        }
    }
}
